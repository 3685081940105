import React from 'react'
// import PropTypes from 'prop-types'
import s from './style.module.scss'

const C = ({ children }) => (
  <div className={s.container}>
    <pre>
      <code>{children}</code>
    </pre>
  </div>
)

C.propTypes = {}

C.defaultProps = {}

export default C
