import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import s from './simple_link.module.scss'

const SimpleLink = ({ href, text }) => {
  const internal = href.charAt(0) === '/'
  return internal ? (
    <Link className={s.link} to={href}>
      {text}
    </Link>
  ) : (
    <a className={s.link} href={href} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  )
}

SimpleLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default SimpleLink
