import React from 'react'
import PropTypes from 'prop-types'
import range from 'lodash.range'
import noise from 'noisejs'

import s from './proc_gen_world.module.scss'

// const w = require('../../utils/window')

const GroundColumn = ({ offset, height, width }) => (
  <div
    className={s.groundColumn}
    style={{
      left: `${offset * width}px`,
      height: `${50 + 25 * height}%`,
      width: `${width}px`,
    }}
  />
)

const RainColumn = ({ offset, width, snow }) => (
  <div
    className={s.rainColumn}
    style={{
      left: `${offset * width}px`,
      width: `${width}px`,
      backgroundColor: snow ? 'rgb(255, 255, 255, 0.6)' : 'currentColor',
    }}
  />
)

const GroundGraphPoint = ({ offset, height, width, color }) => (
  <div
    className={s.groundGraphPoint}
    style={{
      left: `${offset * width + width / 3}px`,
      height: `${50 + 25 * height}%`,
      width: `${width / 3}px`,
      borderColor: color ? color : 'currentColor',
    }}
  />
)

const RainThresholdColumn = ({ offset, height, width }) => (
  <div
    className={s.rainThresholdColumn}
    style={{
      left: `${offset * width}px`,
      height: `${50 + 25 * height}%`,
      width: `${width}px`,
    }}
  />
)

const SnowThresholdColumn = ({ offset, height, width }) => (
  <div
    className={s.snowThresholdColumn}
    style={{
      left: `${offset * width}px`,
      height: `${50 + 25 * height}%`,
      width: `${width}px`,
    }}
  />
)

class ProcGenWorld extends React.Component {
  constructor(props) {
    super(props)
    this.state = { x: 0, regenCount: 0 }
    this.worldEl = null
  }

  componentDidMount() {
    this.onRegenerate()
  }

  onRegenerate() {
    this.setState({ regenCount: (this.state.regenCount + 1) % 1000 })
  }

  worldRef(el) {
    if (!el) {
      return
    }
    this.worldEl = el
    this.worldEl.scrollTo(this.props.cellWidth * this.props.buffer, 0)
  }

  render() {
    // if (w().ssr) { return null }
    const { x } = this.state
    const {
      getGroundHeight,
      groundAdder1,
      rainIntensity,
      rainThreshold,
      temperature,
      snowTemperatureThreshold,
      cellWidth,
      controls,
      buffer,
    } = this.props
    const {
      hideGroundHeightGraph,
      hideGroundAdder1Graph,
      hideRainGraphs,
    } = this.props
    const visibleWidth = this.worldEl
      ? this.worldEl.parentElement.scrollWidth
      : 0
    const numColumns = Math.ceil(visibleWidth / cellWidth + buffer * 2)
    this.noise = new noise.Noise(Math.random())
    const heights = range(x - buffer, x - buffer + numColumns).map(currX =>
      getGroundHeight.bind(this)(currX / (35 / cellWidth))
    )
    const adders1 = groundAdder1
      ? range(x - buffer, x - buffer + numColumns).map(currX =>
          groundAdder1.bind(this)(currX / (35 / cellWidth))
        )
      : null
    const rains = rainIntensity
      ? range(x - buffer, x - buffer + numColumns).map(currX =>
          rainIntensity.bind(this)(currX / (35 / cellWidth))
        )
      : null
    const rainThresholdValue = rainThreshold()
    const temperatures = temperature
      ? range(x - buffer, x - buffer + numColumns).map(currX =>
          temperature.bind(this)(currX / (35 / cellWidth))
        )
      : null
    const snowTemperatureThresholdValue = snowTemperatureThreshold()
    return (
      <div className={s.container}>
        <div className={s.world} ref={this.worldRef.bind(this)}>
          <div className={s.ground}>
            {range(x - buffer, x - buffer + numColumns).map((currX, i) => (
              <GroundColumn
                key={`ground-column-${currX}`}
                offset={currX - x + buffer}
                height={heights[i] + (adders1 ? adders1[i] : 0)}
                width={cellWidth}
              />
            ))}
            {!hideGroundHeightGraph &&
              range(x - buffer, x - buffer + numColumns).map((currX, i) => (
                <GroundGraphPoint
                  key={`ground-graph-point-${currX}`}
                  offset={currX - x + buffer}
                  height={heights[i]}
                  width={cellWidth}
                />
              ))}
            {!hideGroundAdder1Graph &&
              adders1 &&
              range(x - buffer, x - buffer + numColumns).map((currX, i) => (
                <GroundGraphPoint
                  color="rgb(178, 43, 182)"
                  key={`ground-graph-point-${currX}`}
                  offset={currX - x + buffer}
                  height={adders1[i]}
                  width={cellWidth}
                />
              ))}
            {!hideRainGraphs &&
              rains &&
              range(x - buffer, x - buffer + numColumns).map((currX, i) => (
                <RainThresholdColumn
                  key={`rain-threshold-${currX}`}
                  offset={currX - x + buffer}
                  height={rainThresholdValue}
                  width={cellWidth}
                />
              ))}
            {!hideRainGraphs &&
              rains &&
              range(x - buffer, x - buffer + numColumns).map((currX, i) => (
                <GroundGraphPoint
                  color="#2977ff"
                  key={`rain-graph-point-${currX}`}
                  offset={currX - x + buffer}
                  height={rains[i]}
                  width={cellWidth}
                />
              ))}
            {temperatures &&
              range(x - buffer, x - buffer + numColumns).map((currX, i) => (
                <SnowThresholdColumn
                  key={`snow-threshold-${currX}`}
                  offset={currX - x + buffer}
                  height={snowTemperatureThresholdValue}
                  width={cellWidth}
                />
              ))}
            {temperatures &&
              range(x - buffer, x - buffer + numColumns).map((currX, i) => (
                <GroundGraphPoint
                  color="yellow"
                  key={`temperature-graph-point-${currX}`}
                  offset={currX - x + buffer}
                  height={temperatures[i]}
                  width={cellWidth}
                />
              ))}
            {rains &&
              range(x - buffer, x - buffer + numColumns).map((currX, i) =>
                rains[i] >= rainThresholdValue ? (
                  <RainColumn
                    key={`rain-column-${currX}`}
                    offset={currX - x + buffer}
                    height={rains[i] + 0.2}
                    width={cellWidth}
                    snow={
                      temperatures &&
                      temperatures[i] <= snowTemperatureThresholdValue
                    }
                  />
                ) : null
              )}
          </div>
        </div>
        {controls && (
          <div className={s.controls}>
            <button
              className={`${s.regenerateButton} ${s.button}`}
              onClick={this.onRegenerate.bind(this)}
            >
              Regenerate
            </button>
          </div>
        )}
      </div>
    )
  }
}

ProcGenWorld.propTypes = {
  cellWidth: PropTypes.number,
  buffer: PropTypes.number,
  onMount: PropTypes.func,
  getGroundHeight: PropTypes.func.isRequired,
  groundAdder1: PropTypes.func,
  rainIntensity: PropTypes.func,
  rainThreshold: PropTypes.func,
  hideRainGraphs: PropTypes.bool,
  temperature: PropTypes.func,
  snowTemperatureThreshold: PropTypes.func,
  controls: PropTypes.bool,
  hideGroundHeightGraph: PropTypes.bool,
  hideGroundAdder1Graph: PropTypes.bool,
}

ProcGenWorld.defaultProps = {
  cellWidth: 10,
  buffer: 100,
  controls: true,
  hideGroundHeightGraph: false,
  hideGroundAdder1Graph: false,
  hideRainGraphs: false,
  rainThreshold: () => 0.2,
  snowTemperatureThreshold: () => 0,
}

export default ProcGenWorld
