import React from 'react'
import PropTypes from 'prop-types'
import s from './style.module.scss'

const C = ({ src, alt }) => (
  <div className={s.container}>
    <img src={src} alt={alt} className={s.img} />
  </div>
)

C.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

C.defaultProps = {}

export default C
