import React from 'react'
import PropTypes from 'prop-types'

import s from './wikipedia.module.scss'

const Wikipedia = ({ id, text }) => (
  <a
    className={s.link}
    href={`https://wikipedia.org/wiki/${id}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {text}
  </a>
)

Wikipedia.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default Wikipedia
